import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";
import FLOW from "../../../components/flow";
const bg = "../../../images/employment/training/training_org.png";
const bg_b = "../../../images/employment/training/bg_b.png";
const bg_text_v = "../../../images/employment//training/bg_text_v.png";
const bg_text_h = "../../../images/employment//training/bg_text_h.png";
const kv_h = "../../../images/employment/training/kv_h.jpg";
const kv_v = "../../../images/employment/training/kv_v.png";
const img1_h = "../../../images/employment/training/img1_h.jpg";
const img1_v = "../../../images/employment/training/img1_v.png";
const img2_h = "../../../images/employment/training/img2_h.jpg";
const img2_v = "../../../images/employment/training/img2_v.png";
const img3_h = "../../../images/employment/training/img3_h.jpg";
const img3_v = "../../../images/employment/training/img3_v.png";
const img4_h = "../../../images/employment/training/img4_h.jpg";
const img4_v = "../../../images/employment/training/img4_v.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout addClassName="bg-gray-light">
			<SEO
				description="ALPHA WAVEの社内研修です。研修の特徴、カリキュラムなどをご覧ください。"
				title="Training"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full xl:w-10/12 absolute top-144 xl:top-40 right-0 z-0" src={bg} />
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Employment information <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>採用情報</span></p>
					</div>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full"} src={kv_v} />
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h1 className="text-3xl sm:text-5xl 3xl:text-5xl mb-8 xl:mb-16 font-normal xl:font-light sm:tracking-tight 3xl:tracking-tighter"><span className="inline-block">社内研修に</span><span className="inline-block">自信があります！</span></h1>
							<p>当社にとってお客様は大切な財産です。お客様との信頼関係を築きあげることは簡単な事ではありませんが、創業からおよそ30年間積み上げてきた経験と実績で、お客様から多大な信用をいただいております。</p>
							<p>そして、その信頼関係を築いてきた社員達もまた、当社の大切な財産です。</p>
							<p>新入社員研修は、みなさんが受講する社内研修の第一歩であると同時に、当社が社員を大切にする第一歩でもあるのです。 </p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={kv_h} />
					<div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS + " relative xl:text-base"}>
						<div className="z-30">
							<h3 className={CONST.H2_CLASS + " 3xl:tracking-wider"}>研修の特徴</h3>
							<p className="font-bold xl:-mt-10">講師は社内のＳＥです</p>
							<p>新入社員と同じように新人研修で育った先輩が教えるので、「新入社員にとって分からないところやその理由」をきちんと理解して適切にフォローしていきます。</p><br />
							<p className="font-bold">プログラム未経験者もＯＫ</p>
							<p>全く未経験で入社される方の割合が6割以上です。約3ヶ月の研修期間で初級プログラマに成長出来ます。</p><br />
							<p className="font-bold">講義形式ではなく実習形式。進捗は個人ペース</p>
							<p>基本的に個人のペースで学習します。理解出来ないまま先に進むことはありません。</p><br />
							<p className="font-bold">答えではなくヒントを与えることで「考える力」を育む</p>
							<p>ＳＥには“じっくりと考えて行動する力”が求められています。考える力を養うための当社の研修手法は、「新入社員には答えではなくヒントを与えること」です。研修は実習形式で進みます。その過程で分からないことがあった時に(1)先輩に答え(解決法)を聞いて知る方法と、(2)解決に向かうためのヒントをもらって自分で答えを導き出す方法を比べると、(2)の方が「自分で考えて対処する」力が養われますし、プログラムへの理解も深まるはずです。</p>
						</div>
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={"xl:hidden " + CONST.TRAINIG_BACK_GROUND_TEXT_CLASS} src={bg_text_v} />
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " xl:-mt-24 2xl:-mt-32 3xl:-mt-48"}>
				<h2 className={CONST.H2_CLASS + " 3xl:tracking-wider z-30"}>研修カリキュラム</h2>
				<FLOW />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={"hidden xl:block " + CONST.TRAINIG_BACK_GROUND_TEXT_CLASS} src={bg_text_h} />
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS + " mt-10"}>研修スタート</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={img1_h} />
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full -mb-10 xl:mb-0"} src={img1_v} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>研修スタート</h2>
						<p className="xl:-mt-10">期待と緊張が入り混じった中で新入社員研修はスタートします。新入社員だけではなく教える講師（当社社員）側も緊張していますが、準備をしてきたので自信は持っています。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_REVERSE_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>研修の進め方</h2>
						<p className="xl:-mt-10">講義や実習で理解しきれなかった所はまず自分で調べて、それでも分からなければ講師へ質問します。</p>
						<p>自分で考えたり調べたりする時間があるので、「何が分からないのかも分からない」という状態から「自分はこれが分からないんだ」と疑問点を把握し、質問内容を明確に出来る段階に進むことが出来ます。そして講師に質問し、答えではなくヒントをもらい、自分で考えて答えを導き出す…ということを繰り返していきます。多少時間はかかりますが理解度はとても深まり、コミュニケーション能力も自然と身に付いていきます。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS + " mt-10"}>研修の進め方</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={img2_h} />
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full -mb-10 xl:mb-0"} src={img2_v} />
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS + " mt-10"}><span className="inline-block">プログラムの他に</span><span className="inline-block">身につける力</span></h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={img3_h} />
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full -mb-10 xl:mb-0"} src={img3_v} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>プログラムの他に身につける力</h2>
						<p className="xl:-mt-10">前日の作業内容や反省点を毎朝報告する他、調べた内容を発表したりディスカッションしたり。こうしてプログラミング能力だけでなく、技術的な会話力や自分で調べる力、プレゼンテーション能力も磨かれていきます。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_REVERSE_CLASS + CONST.PB_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>現場配属後は...</h2>
						<p className="xl:-mt-10">新人はみんな努力によって成長してきたので、配属後も物怖じすることなく、先輩社員とも堂々と渡り合うことができます。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS + " mt-10"}>現場配属後は...</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={img4_h} />
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full -mb-10 xl:mb-0"} src={img4_v} />
				</div>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full absolute bottom-0 right-0 z-0"} src={bg_b} />
			</section>
		</Layout >
	);
}

export default IndexPage;
