import React from "react";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as CONST from "./const";

//https://codesandbox.io/s/custom-expansionpanelsummary-theme-e4s6n?fontsize=14&hidenavigation=1&theme=dark&file=/demo.jsx:36-50

function Flow() {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = withStyles({
    root: {
      marginBottom: 5,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '5px 0',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#ffffff',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  // const AccordionDetails = withStyles((theme) => ({
  //   root: {
  //     paddingRight: theme.spacing(5),
  //   },
  // }))(MuiAccordionDetails);

  const qaList = [
    {
      id: 'panel1d-header', panel: 'panel1', q: 'フローチャート',
      a: '<p>研修で初めに使用する言語はJavaです。Java言語を通じてプログラムの作成に必要な基礎知識を学習します。フローチャートを元に、簡単なプログラムを作成します。</p>'
        + '<p>基本／条件分岐／繰り返し／配列／総合問題、など</p>'
    },
    {
      id: 'panel2d-header', panel: 'panel2', q: 'プログラミング基礎',
      a: '<p>まずはフローチャートから学びます。プログラムの設計を簡単な図で表現したもので、最終選考の体験研修にも採用しています。無駄のない効率的なプログラムを作成するためには、どうすべきかを考えます。</p>'
        + '<p>Ｊａｖａとは／環境準備、データ型、基本／条件分岐／繰り返し／配列、メソッド（関数）</p>'
    },
    {
      id: 'panel3d-header', panel: 'panel3', q: 'クラス設計・オブジェクト指向',
      a: '<p>プログラムの基礎や効率的なプログラム、そしてオブジェクト指向という考え方をJavaを使用して学習します。同時に、UMLクラス図やシーケンス図を勉強します。</p>'
        + '<p>クラスとオブジェクト、クラス継承、インターフェイスの実装、クラス図／シーケンス図、ＡＰＩの利用</p>'
    },
    {
      id: 'panel4d-header', panel: 'panel4', q: 'Ｃ言語プログラミング',
      a: '<p>ポインタの操作とメモリ操作の技術を身につけます。</p>'
        + '<p>ポインタ、ポインタのポインタ、配列とポインタ、関数とポインタ、動的メモリ確保、構造体、データ構造（チェーン構造）</p>'
    },
    {
      id: 'panel5d-header', panel: 'panel5', q: 'データベース基礎',
      a: '<p>データベースの基本を理解します。</p>'
        + '<p>テーブルの理解と作成、テーブル操作の基本、結合／サブクエリ、トランザクション、インデックスの目的と効果</p>'
    },
    {
      id: 'panel6d-header', panel: 'panel6', q: 'その他',
      a: '<p>ネットワーク、情報処理基礎概論、Ｗｅｂプログラミングなど</p>'
    },
  ];

  return (
    <React.Fragment>
      <div className={CONST.PC_SHOW_CLASS + " z-30"}>
        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6 gap-3">
          {
            qaList.map((qa) => (
              <div key={qa.id} className={CONST.BG_WHITE_BLOCK}>
                <p className="text-base py-5">{qa.q}</p>
                <div dangerouslySetInnerHTML={{ __html: qa.a }}></div>
              </div>
            ))
          }

        </div>
      </div>
      <div className={CONST.PC_HIDDEN_CLASS + " z-30"}>
        {
          qaList.map((qa) => (
            <Accordion key={qa.id} square expanded={expanded === qa.panel} onChange={handleChange(qa.panel)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon className="text-5xl" />} aria-controls={qa.panel} id={qa.id}>
                <p className="px-5 w-full">{qa.q}</p>
              </AccordionSummary>
              <MuiAccordionDetails>
                <div className="px-5 w-full" dangerouslySetInnerHTML={{ __html: qa.a }}></div>
              </MuiAccordionDetails>
            </Accordion>
          ))
        }
      </div >
    </React.Fragment>
  );
}

export default Flow;